<template>
  <div class="bordered">
    <Title>收入指标</Title>
    <div class="m_xnorm" v-if="isData">
      <div class="rows no">
        <img src="../assets/cu4.png" class="icon" />
        <div class="right">
          <h4 class="top">每职工平均本期盈余<em>
              <countTo class="big" :startVal="0" :endVal="indicatorsObj.employeeAverageCurrentSurplusPer"
                :duration="CountAnimationDuration"></countTo>元
            </em></h4>
          <h4>{本期收入-本期费用 (本期支出)}/平均职工人数</h4>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>医疗服务收入占比</b>
            <div class="us">
              <countTo :startVal="0" :endVal="indicatorsObj.medicalServiceIncomeRate" :duration="CountAnimationDuration"
                :decimals="2"></countTo>%
            </div>
          </div>
          <div class="bg"><i
              :style="{ transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: indicatorsObj.medicalServiceIncomeRate + '%' }"><dfn></dfn></i>
          </div>
        </div>
      </div>
      <div class="rows">
        <div class="m_xare">
          <div class="our">
            <b>人员支出占业务支出比例</b>
            <div class="us">
              <countTo :startVal="0" :endVal="indicatorsObj.operationalPersonnelExpenditureRate"
                :duration="CountAnimationDuration" :decimals="2"></countTo>%
            </div>
          </div>
          <div class="bg"><i
              :style="{ transition: `all ${CountAnimationDuration / 2}ms ease-in-out`, width: indicatorsObj.operationalPersonnelExpenditureRate + '%' }"><dfn></dfn></i>
          </div>
        </div>
      </div>
    </div>
    <div class="m_xnodata" v-else>
      <img src="@/assets/imgs/noData.png" alt="" />
      <em>暂无数据</em>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: { Title, countTo },
  data() {
    return {
      CountAnimationDuration,
      isData: false
    }
  },
  props: ['indicatorsObj'],
  watch: {
    indicatorsObj: {
      handler(val) {
        let length = Number(val.employeeAverageCurrentSurplusPer) + Number(val.medicalServiceIncomeRate) + Number(val.operationalPersonnelExpenditureRate)
        if (length == 0) {
          this.isData = false
        } else {
          this.isData = true
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered {
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}

.m_xnorm {
  padding: 0 18px;

  .rows {
    overflow: hidden;
    margin: 24px 0;
    background: #0A2C5F;
    padding: 22px 22px 30px;
  }

  .no {
    padding: 21px 22px;
  }

  .icon {
    width: 60px;
    height: 60px;
    float: left;
  }

  .right {
    margin-left: 77px;
  }

  h4 {
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    vertical-align: bottom;

    em {
      display: inline-block;
      font-style: normal;
      color: #3EDFAE;
    }
  }

  .top {
    margin-top: -5px;
  }

  .big {
    font-size: 32px;
    color: #3EDFAE;
    margin: 0 -5px 0 10px;
    font-weight: normal;
  }
}

.m_xare .our {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;

  b {
    font-weight: normal;
    position: relative;
    padding-left: 18px;
  }

  b::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #05BDCD;
    left: 0;
    top: 50%;
    margin-top: -5px;
  }

  .us {
    color: #33D69C;
    font-size: 20px;
  }
}

.m_xare .bg {
  background: #16356F;
  margin-top: 12px;

  i {
    display: block;
    height: 14px;
    background: linear-gradient(93deg, #02ADC0 0%, #50AD8C 100%);
    opacity: 0.89;
    position: relative;
  }

  .blue {
    background: linear-gradient(93deg, #72E4F3 0%, #4AAEFF 100%);
  }

  dfn {
    position: absolute;
    height: 14px;
    background: url('../assets/wire.png') repeat-x 0 0;
    background-size: auto 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.m_xnodata {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 408px;

  em {
    font-style: normal;
  }
}</style>