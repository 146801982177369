<template>
	<div class="bordered mt16">
		<Title>能力指标</Title>
		<!-- <div class="m_xares" v-if="isData">
			<div class="line" v-for="(v, i) in essenFun" :key="i">
				<div class="value"><countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>%</div>
				<p>{{ v.name }}</p>
			</div>
		</div> -->

		<!-- <BaseEchart :option="option" style="width: 100%; height: 328px"></BaseEchart> -->
		<div class="m_xnodata">
			<img src="@/assets/imgs/noData.png" alt="" />
			<em>暂无数据</em>
		</div>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import BaseEchart from '@/components/BaseEchart';
import { barChart } from '../common';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
	components: { Title, countTo, BaseEchart },
	data() {
		return {
			CountAnimationDuration,
			essenArr: [{ value: 78, name: '职业医师' }, { value: 56, name: '医护' }, { value: 40, name: '中医类别' }, { value: 78, name: '高级职称' }],
			option: null
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		essenFun() {
			return this.essenArr.map(s => {
				return {
					...s,
					value: s.value / (this.mapLevel + 1)
				}
			})
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			let arry = ['职业医师', '医护', '中医类别', '高级职称']
			const dataX = arry.map((k, index) => {
				return { name: k, value: this.essenArr[index].value }
			})
			this.option = barChart({ xData: dataX.map(d => d.name), yData: dataX.map(d => d.value) })
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';

.bordered {
	background: rgba(5, 41, 92, .6);
	border: 2px solid rgba(17, 94, 205, .2);
}

.m_xares {
	display: flex;
	padding: 16px 8px;
	text-align: center;

	.line {
		flex: 1;
		height: 74px;
		background: rgba(28, 135, 255, 0.25);
		margin: 0 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p {
		font-size: 14px;
		color: #fff;
		padding-top: 4px;
	}
}

.value {
	color: $main-text;
	font-size: 20px;
}
.m_xnodata {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 434px;

  em {
    font-style: normal;
  }
}
</style>
