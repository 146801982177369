<template>
  <div class="m_xwrap">
    <div class="m_xontus top">
      <h4>省分</h4>
      <div class="m_xterm">
        <div :class="isup ? 'up' : 'un'">
          <el-tooltip v-for="(v, i) in china" :key="i" class="item" effect="dark" :content="v.name" :disabled="v.name.length > 6 ? false : true" placement="top">
            <em @click="provFun(v, i)" :class="provIdx == i ? 'on' : ''">{{ v.name }}</em>
          </el-tooltip>
        </div>
      </div>
      <div class="m_xoper">
        <dfn @click="upFun">展开<i :class="isup ? '' : 'up'"></i></dfn>
      </div>
    </div>
    <div class="m_xontus">
      <h4>地市</h4>
      <div class="m_xterm">
        <el-tooltip v-for="(v, i) in cityArr" :key="i" class="item" effect="dark" :content="v.name" placement="top" :disabled="v.name.length > 6 ? false : true">
          <em @click="cityFun(v, i)" :class="cityIdx == i ? 'on' : ''">{{ v.name }}</em>
        </el-tooltip>
      </div>
    </div>
    <div class="m_xontus">
      <h4>区县</h4>
      <div class="m_xterm">
        <el-tooltip v-for="(v, i) in areaArr" :key="i" class="item" effect="dark" :content="v.name" placement="top" :disabled="v.name.length > 6 ? false : true">
          <em @click="areaFun(v, i)" :class="areaIdx == i ? 'on' : ''">{{ v.name }}</em>
        </el-tooltip>
      </div>
    </div>
    <div class="m_xontus">
      <h4>医疗机构</h4>
      <div class="no" v-if="communArr.length == 0">
        <img src="@/assets/imgs/noData.png" alt="" />
        <em>暂无数据</em>
      </div>
      <p v-for="(v, i) in communArr" :class="{'on': communIdx == i}" @click="healthFun(v, i)"><em>{{ i+1 }}</em>{{ v.name }}</p>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import chinas from '@/utils/pca-code';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: { Title, countTo },
  data() {
    return {
      CountAnimationDuration,
      china: chinas,
      provIdx: 0,
      cityArr: [],
      cityIdx: 0,
      areaArr: [],
      areaIdx: 0,
      isup: true,
      communIdx: 0,
      communArr: [],
      regionCode: JSON.parse(sessionStorage.getItem('userInfo')).regionCode
    }
  },
  computed: {
  },
  mounted() {
    this.initial()
  },
  methods: {
    async initial() {
      let code = this.regionCode
      let prov = '', city = '', area = '';
      if(code.length == 2){
        prov = code
      }else if(code.length == 4){
        prov = code.substring(0, 2);
        city = code.substring(0, 4);
      }else{
        prov = code.substring(0, 2);
        city = code.substring(0, 4);
        area = code
      }
      await this.china.map((v, i) => { if(prov == v.code) this.provIdx = i })
      let arr = this.china[this.provIdx].children
      if(city != ''){
        await arr.map((v, i) => { if(city == v.code) this.cityIdx = i })
      }
      this.cityArr = arr
      let cous = arr[this.cityIdx].children
      if(area != ''){
        await cous.map((v, i) => { if(area == v.code) this.areaIdx = i })
      }
      this.areaArr = cous
      this.communFun()
    },
    async communFun(code) {
      let par = {regionCode: code ? code : this.regionCode, current: 1, size: 999}
      this.$http.get('/management-api/v1/MedicalOrganizations.json',{params: par}).then((res) => {
        if (res.data && res.data.success) {
          this.$emit('healthChange', res.data.collection[0])
          this.communArr = res.data.collection
        }else{
          this.communArr = []
          this.$emit('healthChange', '')
        }
			}).catch((err) => {
        this.communArr = []
        this.$emit('healthChange', '')
      });
    },
    healthFun(v, i) {
      this.communIdx = i
      this.$emit('healthChange', v)
    },
    provFun(v, i) {
      this.provIdx = i
      this.cityIdx = 0
      this.areaIdx = 0
      this.cityArr = v.children
      this.areaArr = v.children[0].children
      this.communFun(v.children[0].children[0].code)
    },
    cityFun(v, i) {
      this.cityIdx = i
      this.areaIdx = 0
      this.areaArr = v.children
      this.communFun(v.children[0].code)
    },
    areaFun(v, i) {
      this.areaIdx = i
      this.communFun(v.code)
    },
    upFun() {
      this.isup = !this.isup
    }
  }
}
</script>
<style lang="scss" scoped>
.m_xwrap {
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  height: 930px;
  overflow-y: auto;
}
.m_xontus {
  padding: 12px 10px 10px;
}
.m_xontus.top {
  border-bottom: 1px solid #21417A;
  padding: 20px 10px 10px;
}
.m_xontus h4 {
  position: relative;
  padding-left: 16px;
  font-size: 16px;
  color: #fff;
}
.m_xontus h4::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 12px;
  left: 5px;
  top: 50%;
  margin-top: -6px;
  background: linear-gradient(0deg, rgba(10, 145, 239, 1), rgba(17, 94, 205, 1));
}
.m_xontus p {
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
}
.m_xontus p.on{
  background: #388ED2;
}
.m_xontus p em {
  width: 16px;
  height: 16px;
  background: #0E3970;
  text-align: center;
  line-height: 16px;
  margin-right: 6px;
  font-style: normal;
}
.m_xterm {
  display: flex;
  flex-wrap: wrap;
}
.m_xterm em {
  width: 18%;
  text-align: center;
  background: #0E3970;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #fff;
  font-style: normal;
  margin: 10px 1% 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.m_xterm em.on {
  background: #388ED2;
}
.m_xterm .up {
  height: 102px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.m_xterm .un {
  display: flex;
  flex-wrap: wrap;
}
.m_xoper {
  padding: 0 1%;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.m_xoper dfn {
  font-style: normal;
  padding: 15px 0 0;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.m_xoper i {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #4CD9F7 transparent transparent transparent;
  transform: rotate(0deg);
  margin-left: 6px;
}
.m_xoper i.up {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #4CD9F7 transparent;
}
.m_xontus .no {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  flex-direction: column;
  em{
    font-style: normal;
  }
}
</style>