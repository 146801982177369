<template>
  <div class="bordered mt16">
    <Title>管理指标</Title>
    <BaseEchart :option="option" style="width: 100%; height: 434px" v-if="isData"></BaseEchart>
    <div class="m_xnodata" v-else>
      <img src="@/assets/imgs/noData.png" alt="" />
      <em>暂无数据</em>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue';
import BaseEchart from '@/components/BaseEchart';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
  components: { Title, BaseEchart },
  data() {
    return {
      CountAnimationDuration,
      option: {},
      isData: false
    };
  },
  props: ['manageIndicators'],
  mounted() {
    // setTimeout(() => {
    //   this.init()
    // }, 500);
  },
  methods: {
    init() {
      let color = ['#348EE9', '#4EF5FF', '#F9B37A', '#FBE06C'];
      let chartData = [
        {
          name: '管理人群血糖控制率',
          value: 85,
          unit: 'Kwh',
        },
        {
          name: '管理人群血压控制率',
          value: 50,
          unit: 'Kwh',
        },
        {
          name: '严重精神障碍患者规范管理率',
          value: 72,
          unit: 'Kwh',
        },
        {
          name: '家庭医生签约服务全人群覆盖率',
          value: 65,
          unit: 'Kwh',
        },
      ];
      chartData.forEach((item, index) => {
        if (index == 0) {
          item.value = this.manageIndicators.diabetesQualifiedRate
        } else if (index == 1) {
          item.value = this.manageIndicators.hypertensionQualifiedRate
        }else if (index == 2) {
          item.value = this.manageIndicators.psychosisHealthServiceRate
        }else if (index == 3) {
          item.value = this.manageIndicators.familyDoctorContractRate
        }
      })
      let length = Number(this.manageIndicators.diabetesQualifiedRate) + Number(this.manageIndicators.hypertensionQualifiedRate) + Number(this.manageIndicators.psychosisHealthServiceRate) + Number(this.manageIndicators.familyDoctorContractRate)
      if (length == 0) {
        this.isData = false
      } else {
        this.isData = true
      }
      let sum = 100;
      let pieSeries = [];
      let lineYAxis = [];
      chartData.sort((a, b) => b.value - a.value).forEach((v, i) => {
        pieSeries.push({
          name: '分布情况',
          type: 'pie',
          bottom: 70,
          clockWise: false,
          hoverAnimation: false,
          radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
          center: ['50%', '50%'],
          label: {
            show: false,
          },
          data: [
            {
              value: v.value,
              name: v.name,
            },
            {
              value: sum - v.value,
              name: '',
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
        });
        pieSeries.push({
          name: '用电量分布情况',
          type: 'pie',
          silent: true,
          z: 1,
          bottom: 70,
          clockWise: false,
          hoverAnimation: false,
          radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
          center: ['50%', '50%'],
          label: {
            show: false,
          },
          data: [
            {
              value: 1,
              itemStyle: {
                color: '#172F6F',
              },
            },
          ],
        });
        v.percent = ((v.value / sum) * 100).toFixed(1) + '%';
        lineYAxis.push({
          value: i,
          textStyle: {
            rich: {
              circle: {
                color: color[i],
                padding: [0, 5],
              },
            },
          },
        });
      });

      const legendData = chartData.map(d => d.name).slice()
      legendData.splice(2, 0, '')
      this.option = {
        tooltip: {},
        legend: {
          data: legendData,
          bottom: 20,
          formatter: (name) => {
            const percent = chartData.find(d => d.name === name).value
            const idx = chartData.findIndex(d => d.name === name)
            return `{name|${name}} {${idx}|${percent}%}`;
          },
          textStyle: {
            color: 'white',
            rich: {
              name: {
                fontSize: 10,
                width: 145
              },
              percent: {
                color: '#ff00ff'
              },
              "0": {
                color: color[0]
              },
              "1": {
                color: color[1]
              },
              "2": {
                color: color[2]
              },
              "3": {
                color: color[3]
              }
            }
          },
        },
        color: color,
        grid: {
          bottom: '60%',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            data: lineYAxis,
          },
        ],
        xAxis: [
          {
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            show: false,
          },
        ],
        series: pieSeries,
      };
    }
  },
  watch: {
    manageIndicators:{
      handler(val){
        this.init()
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';

.bordered {
  background: rgba(5, 41, 92, 0.6);
  border: 2px solid rgba(17, 94, 205, 0.2);
}

.m_xares {
  display: flex;
  padding: 16px 8px;
  text-align: center;

  .line {
    flex: 1;
    height: 70px;
    background: rgba(28, 135, 255, 0.25);
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    font-size: 14px;
    color: #fff;
    padding-top: 4px;
  }
}

.value {
  color: $main-text;
  font-size: 22px;
}
.m_xnodata{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 35px;
  flex-direction: column;
  height: 434px;
  em{
    font-style: normal;
  }
}
</style>
